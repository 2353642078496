<template>
  <div>
    <section class="home--promo-container">
      <!--      <div class="title&#45;&#45;text">-->
      <!--        {{ $t('home.promo') }}-->
      <!--      </div>-->
      <client-only>
        <div
          v-observe-visibility="{
            callback: visibilityChanged,
            once: true,
          }"
        >
          <div class="slider" v-if="banners && banners.length > 0">
            <slick-banner :banners="banners"></slick-banner>
          </div>
        </div>
      </client-only>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
const SlickBanner = defineAsyncComponent(() => import('./partials/slick-banner.vue'));
export default {
  components: { SlickBanner },
  computed: {
    banners() {
      return this.$store.state.banner.banners;
    },
  },
  methods: {
    visibilityChanged(value) {
      if (value) {
        this.getBanners();
      }
    },
    getBanners() {
      this.$store.dispatch('banner/getBanner');
    },
  },
};
</script>
